import React from 'react'

import Layout from '../components/layout'
import Title from '../components/title'
export { Head } from '../components/head'

const NotFoundPage = () => (
	<Layout>
		<Title>Kitten Not Found</Title>
		<div className="text-xl opacity-70 md:text-2xl">
			<p>
				We’re sorry, but the page or kitten was not found at this
				location.
			</p>
		</div>
	</Layout>
)

export default NotFoundPage
